// Add DOM4 support, https://github.com/WebReflection/dom4
import 'dom4';

// Import all components from Audi UI library
// @ts-ignore
import aui from '@audi/audi-ui';

// Initialize all Audi UI components
aui.upgradeAllElements();


window.addEventListener( 'load', () => {

	if ( document.body.classList.contains('home-page') ) {
		import('./pages/home/home').then( p => new p.Home() );
	}

	if ( document.body.classList.contains('rim-selection-page') ) {
		import('./pages/rim-selection/rim-selection').then( p => new p.RimSelection() );
	}

	if ( document.body.classList.contains('quotation-page') ) {
		import('./pages/quotation/quotation').then( p => new p.Quotation() );
	}

	if ( document.querySelectorAll('.back-bt').length > 0 ) {
		// @ts-ignore
		document.querySelectorAll('.back-bt').forEach( (backBtn: HTMLButtonElement) => {
			import('./components/back-button').then( c => new c.BackButton( backBtn ) );
		});
	}
});

